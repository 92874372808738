import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Helmet } from "react-helmet";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";

const config = require("../../../meta/config");
mapboxgl.accessToken = config.mapboxAccessToken;

const LocationsPage = () => {
  const data = useStaticQuery(locationPageQuery);
  const { edges: locations } = data.allMarkdownRemark;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popup = useRef(null);
  const [lng, setLng] = useState(151.24);
  const [lat, setLat] = useState(-33.898);
  const [zoom, setZoom] = useState(11.7);
  const [locationSidebarItems, setLocationSidebarItems] = useState(null);
  const [highlightKey, setHighlightKey] = useState(null);
  // const [popup, setPopup] = useState(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    if (locations && locations.length > 1) {
      const bounds = new mapboxgl.LngLatBounds([
        [locations[0].node.frontmatter.lng, locations[0].node.frontmatter.lat],
        [locations[0].node.frontmatter.lng, locations[0].node.frontmatter.lat],
      ]);
      locations.forEach(({ node: item }) => {
        bounds.extend([item.frontmatter.lng, item.frontmatter.lat]);
      });
      map.current.fitBounds(bounds, { padding: 60 });
    }
    const nav = new mapboxgl.NavigationControl({ showCompass: false });
    map.current.addControl(nav, "top-left");
  });
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });
  const showPopup = (lnglat, location) => {
    if (popup.current) popup.current.remove();
    popup.current = new mapboxgl.Popup({ closeOnClick: false })
      .setLngLat(lnglat)
      .setHTML(
        `<a href="${location.fields.slug}" class="focus:outline-none hover:underline hover:text-feat-blue-700">
          <h3 class="text-bold text-heading uppercase pb-2 text-xl font-bold text-feat-blue hover:text-feat-blue-700">
            FEAT Fitness ${location.frontmatter.location}
          </h3>
        </a>
        <h4 class="text-base text-feat-darkgrey">${location.frontmatter.location_line_2}</h4>`
      )
      .addTo(map.current);
  };
  const popUpAndFlyToLocation = (marker, location, id) => {
    setHighlightKey(id);
    // map.current.fitBounds();
    map.current.flyTo({
      center: marker.getLngLat(),
      zoom: 15,
    });
    showPopup(marker.getLngLat(), location);
  };
  useEffect(() => {
    if (!map.current) return;
    setLocationSidebarItems(
      locations.map(({ node: item }) => {
        const marker = new mapboxgl.Marker({ color: "#515e65" })
          .setLngLat([item.frontmatter.lng, item.frontmatter.lat])
          .on("click")
          .addTo(map.current);
        marker.getElement().addEventListener("click", () => {
          popUpAndFlyToLocation(marker, item, item.id);
        });
        return (
          <span
            key={item.frontmatter.location}
            // to={item.fields.slug}
            onClick={() => popUpAndFlyToLocation(marker, item, item.id)}
            onMouseOver={
              () => showPopup(marker.getLngLat(), item)
              // popUpAndFlyToLocation(marker, item.frontmatter, item.id)
            }
            className={classNames(
              highlightKey === item.id
                ? "bg-feat-lightgrey-900 text-white"
                : "text-feat-darkgray-500 bg-feat-lightgrey-500",
              "group flex flex-col items-start px-4 py-2 md:py-4 text-sm font-medium space-y-1 md:space-y-2 mb-2 cursor-pointer  hover:bg-gray-700 hover:text-white"
            )}
          >
            <span className="text-heading uppercase font-semibold">
              {item.frontmatter.location}
            </span>
            <span className="font-light">
              {item.frontmatter.location_line_2}
            </span>
          </span>
        );
      })
    );
  }, [map, highlightKey]);

  return (
    <Layout>
      <Seo
        title="Locations & Training at FEAT Fitness"
        meta_title="Locations & Training at FEAT Fitness"
        meta_desc="See our locations around Sydney and find your local FEAT Fitness to start with today. FEAT Fitness are the outdoor experts and deliver fun community outdoor training."
        slug="location"
      />
      <Helmet>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>

      {/* Static sidebar for desktop */}
      <div className="h-screen-navbar flex-col md:flex-row flex overflow-hidden bg-feat-lightgray">
        <div className="flex flex-1 h-1/2 md:h-full md:flex-initial md:flex-shrink-0 md:border-r border-feat-lightgrey-500 ">
          <div className="flex flex-col w-full md:w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 bg-feat-lightgrey-200">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <h1 className="hidden md:block mx-auto px-4 mb-5 text-feat-darkgrey text-heading uppercase text-center">
                  FEAT Fitness Locations
                </h1>
                <nav className="flex-1 flex flex-col px-2 bg-feat-lightgrey-200 last:mt-auto">
                  {locationSidebarItems}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 md:overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div
              ref={mapContainer}
              className="map-container h-full md:h-screen-navbar"
            />
            {/* <a
              href="#"
              className="
              hidden
              md:flex
              absolute
              bottom-3
              right-3
              lg:bottom-auto
              lg:top-3
              z-40
              max-w-xs
              bg-feat-darkgray
              bg-opacity-70	
              text-white
              border-2 
              border-dashed 
              hover:bg-opacity-100	
              group
              flex-col
              items-start
              px-4
              py-4
              text-sm
              font-medium
              space-y-2
              mt-auto
              "
            >
              Can't find a FEAT location near you? Express your interest
            </a> */}
          </main>
        </div>
      </div>
    </Layout>
  );
};

export default LocationsPage;

export const locationPageQuery = graphql`
  query LocationPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "location-page" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            location
            location_line_2
            templateKey
            lat
            lng
            google_map_id
            timetable {
              day_of_week
              sessions {
                time_slot_name
                session
                type
              }
            }
          }
        }
      }
    }
  }
`;
